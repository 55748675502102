<template>
  <div
    ref="slider"
    class="slider-container"
    @mouseover="pause()"
    @mouseout="play()"
  >
    <div
      class="slider-content"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        class="slider"
        :class="setClass(index)"
        @click="onClick(index)"
      >
        <img :src="item.src">
      </div>
      <div
        v-show="arrow"
        class="arrow-pre"
        @click="prev()"
      >
        <i class="iconfont icon-home-naxt" />
      </div>
      <div
        v-show="arrow"
        class="arrow-next"
        @click="next()"
      >
        <i class="iconfont icon-home-naxt" />
      </div>
    </div>
    <div
      v-if="dots"
      class="dots"
    >
      <span
        v-for="(item, index) in list"
        :key="index"
        :class="getActiveClass(index)"
        @click="currentIndex = index"
      />
    </div>
  </div>
</template>

<script>
import { throttle } from '@/utils/debounce'
  export default {
    props: {
      list: {
        required: true,
        type: Array,
        default () {
          return []
        }
      },
      autoPlay: {
        type: Boolean,
        default: true
      },
      interval: {
        type: Number,
        default: 3000
      },
      dots: {
        type: Boolean,
        default: true
      },
      arrow: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        currentIndex: 0,
        sliderDomList: [],
        timer: null
      }
    },
    watch: {
      currentIndex(val) {
        this.$emit('change', val)
      }
    },
    mounted () {
      this.sliderDomList =  this.$refs.slider.querySelectorAll('div.slider');
      this.play();
    },
    methods: {
      setClass (i) {
        let next = this.currentIndex === (this.list.length - 1) ? 0 : this.currentIndex + 1;
        let prev = this.currentIndex === 0 ? this.list.length - 1 : this.currentIndex - 1;
        switch (i) {
          case this.currentIndex:
            return 'active';
          case next:
            return 'next';
          case prev:
            return 'prev';
          default:
            return '';
        }
      },
      getActiveClass (index) {
        return index === this.currentIndex ? 'activeDot' : ''
      },
      play () {
        this.pause();
        if (this.autoPlay) {
          this.timer = setInterval(()=>{
            this.next();
          }, this.interval)
        }
      },
      pause () {
        clearInterval(this.timer);
      },
      next: throttle(function() {
        this.currentIndex = ++this.currentIndex % this.list.length;
      }, 500),
      prev: throttle(function () {
        this.currentIndex = this.currentIndex === 0 ? this.list.length - 1 : this.currentIndex - 1;
      }, 500),
      onClick (i) {
        if (i === this.currentIndex){
          console.log('点当前')
        } else {
          let currentClickClassName = this.sliderDomList[i].className.split(' ')[1]
          if (currentClickClassName === 'next') {
            console.log('点击下一个')
          } else {
            console.log('点击上一个')
          }
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.slider-container{
  width: 1200px;
  height: 522px;
  margin-top: 36px;
  text-align: center;
  padding: 10px 0;
  position: relative;
  .slider-content {
    position: relative;
    width: 100%;
    height: calc(100% - 20px);
    left: 0%;
    top: 0%;
    margin: 0px;
    padding: 0px;
    background-size: inherit;
    .slider {
      position: absolute;
      margin: 0;
      padding: 0;
      top: 8px;
      left: 50%;
      width: 600px;
      height: 384px;
      transition: 500ms all ease-in-out;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: inherit;
      transform: translate3d(-50%,0,-80px);
      z-index: 1;
      &>img {
        width: 100%;
        height: 100%;
        opacity: 0.5;
        transition: 500ms all ease-in-out;
      }
      &.active {
        height: 396px;
        top: 0;
        transform: translate3d(-50%, 0, 0);
        z-index: 20;
        &>img {
          opacity: 1;
        }
      }
      &.prev {
        transform: translate3d(-75%, 0, 0);
        z-index: 19;
      }
      &.next {
        transform: translate3d(-25%, 0, 0);
        z-index: 18;
      }
    }
    .arrow-pre {
      display: none;
      width: 63px;
      height: 63px;
      align-items: center;
      justify-content: center;
      border: 0.75px solid #CE9B63;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 166px;
      transform: rotate(180deg);
      .iconfont {
        font-size: 28px;
        color: #CE9B63;
      }
      &:hover {
        background: #CE9B63;
        .iconfont {
          color: #fff;
        }
      }
    }
    .arrow-next {
      display: none;
      width: 63px;
      height: 63px;
      align-items: center;
      justify-content: center;
      border: 0.75px solid #CE9B63;
      border-radius: 50%;
      position: absolute;
      right: 0;
      top: 166px;
      .iconfont {
        font-size: 28px;
        color: #CE9B63;
      }
      &:hover {
        background: #CE9B63;
        .iconfont {
          color: #fff;
        }
      }
    }
    &:hover {
      .arrow-pre, .arrow-next {
        display: flex;
      }
    }
  }
  .dots {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      display: inline-flex;
      box-sizing: border-box;
      width: 12px;
      height: 12px;
      margin-left: 13.5px;
      background: #E0E2E8;
      border-radius: 50%;
      cursor: pointer;
      &:nth-child(1) {
        margin-left: 0;
      }
      &.activeDot {
        background: #fff;
        border: 0.75px solid #CE9B63;
        
        align-items: center;
        justify-content: center;
        &::after {
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #CE9B63;
          border-radius: 50%;
        }
      }
    }
  }
}
  
</style>