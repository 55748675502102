<template>
  <div class="hon-wrap">
    <img src="@/assets/images/myFavors/honericon.png">
    <div class="txt-t">
      <span>QUALIFICATION PROOF</span>
      <h3>资质证明</h3>
    </div>
    <h4>{{ list[index].name }}</h4>
    <p>{{ list[index].desc }}</p>
    <em>{{ list[index].code }}</em>
    <qualification :list="list" @change="indexChange" />
  </div>
</template>

<script>
  import qualification from './components/qualification.vue'
  import url1 from '@/assets/images/myFavors/1.jpg'
  import url2 from '@/assets/images/myFavors/2.jpg'
  import url3 from '@/assets/images/myFavors/3.jpg'
  import url4 from '@/assets/images/myFavors/4.jpg'
  export default {
    components: {
      qualification,
    },
    data() {
      return {
        list: [
          {
            title: 1,
            src: url1,
            name: '营业执照',
            desc: '合法经营基金销售的企业法人单位',
            code: '统一社会信用代码：91522636MA6DM9A59H'
          },
          {
            title: 2,
            src: url2,
            name: '合法持牌机构',
            desc: '中国证监会批准的独立基金销售机构',
            code: '经营证券期货业务许可证号：000000043174'
          },
          {
            title: 3,
            src: url3,
            name: '协会会员',
            desc: '中国证券投资基金业协会联席会员',
            code: '会员代码：LX2100011810'
          },
          {
            title: 4,
            src: url4,
            name: '协会会员',
            desc: '贵州证券业协会会员单位',
            code: '证书编号：0228'
          }
        ],
        index: 0
      }
    },
    methods: {
      indexChange(index) {
        this.index = index
      }
    }
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');
.hon-wrap {
  width: 100%;
  padding-top: 103px;
  padding-bottom: 134px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &>img {
    width: 112px;
    height: 112px;
  }
  .txt-t {
    margin-top: 10px;
    position: relative;
    &>h3 {
      width: 320px;
      font-weight: bold;
      font-size: 45px;
      color: #DEB87B;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -8px;
      left: calc(50% - 160px);
      &::before {
        content: '';
        display: inline-block;
        height: 6px;
        width: 60px;
        background: #DEB87B;
        margin-right: 8px;
        opacity: 0.8;
      }
      &::after {
        content: '';
        display: inline-block;
        height: 6px;
        width: 60px;
        background: #DEB87B;
        margin-left: 8px;
        opacity: 0.8;
      }
    }
    &>span {
      font-weight: bold;
      font-size: 60px;
      background-image: linear-gradient(to bottom, rgba(255,245,234,0.2), rgba(222,184,123, 0.2));
      background-clip: text;
      color: transparent;
    }
  }
  &>h4 {
    margin-top: 70px;
    font-weight: normal;
    font-size: 36px;
    color: #1F1F1F;
  }
  &>p {
    margin-top: 20px;
    font-weight: normal;
    font-size: 24px;
    color: #DEB87B;
  }
  &>em {
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #DEB87B;
  }
}
</style>